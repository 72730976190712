import { getDefaultSeasonIndex } from '@canalplus/mycanal-sharedcomponent';
import type { ApiV2Selector } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import type { JSX } from 'react';
import { useMemo } from 'react';
import { useAppLocation } from '../../../../helpers/hooks/reactRouter';
import type { FetchDetails } from '../../../../services/types';
import styles from './EpisodesList.css';
import type {
  InitialDataCurrentPage,
  InitialDataEpisodes,
} from './EpisodesList.types';
import EpisodesContainer from './components/EpisodesContainer/EpisodesContainer';
import { SeasonsTabsContainer } from './components/SeasonsTabsContainer/SeasonsTabsContainer';

export type EpisodesListProps = {
  currentPage: InitialDataCurrentPage;
  episodes: InitialDataEpisodes;
  onClickParameters?: FetchDetails['onClickParameters'];
  seasonsSelector?: ApiV2Selector[];
  URLPage: string;
};

function EpisodesList({
  currentPage,
  episodes: { contents, paging } = {},
  onClickParameters,
  seasonsSelector,
  URLPage,
}: EpisodesListProps): JSX.Element {
  const { pathname } = useAppLocation();

  // (For season context): Computes the default season index to focus and the season url
  const { activeSeasonIndex, isSeasonTargetedByUrl } = useMemo(
    () => getDefaultSeasonIndex(seasonsSelector || [], pathname),
    [seasonsSelector, pathname],
  );
  const activeSeason = seasonsSelector?.[activeSeasonIndex];
  const seasonUrl = activeSeason?.onClick?.URLPage;

  // (For seasonless context): We can directly use the episodes data
  const initialData = useMemo(
    () => ({
      pages: [{ currentPage, episodes: { contents, paging } }],
      pageParams: [],
    }),
    [contents, paging, currentPage],
  );

  // If we are on the root url of the detailPage (without specific season tab url),
  // the main meta of the page must not be overridden by that of the default season tab.
  const disableMetaUpdate = !isSeasonTargetedByUrl;

  return (
    <div className={styles.episodesList}>
      {activeSeason && (
        <SeasonsTabsContainer
          data-testid="EpisodesList-seasons-tabs"
          className={styles.episodesList__seasonsTabs}
          seasons={seasonsSelector}
          itemId="episodesList-season-item"
        />
      )}
      <EpisodesContainer
        disableMetaUpdate={disableMetaUpdate}
        initialData={
          activeSeason?.default || !seasonsSelector ? initialData : undefined
        }
        onClickParameters={onClickParameters}
        seasonUrl={seasonUrl}
        URLPage={URLPage}
      />
    </div>
  );
}

export default EpisodesList;
