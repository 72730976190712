import { useStore } from '@dce-front/one-navigation';
import { useCallback, useEffect, useRef } from 'react';

export type UseForceFocusOnRefetchProps = {
  seasonUrl?: string;
  primaryActionContentId?: string;
};

export const useForceFocusOnRefetch = ({
  seasonUrl,
  primaryActionContentId,
}: UseForceFocusOnRefetchProps): void => {
  const store = useStore();
  const episodeIdPrefix = seasonUrl ? 'episodesList-season' : 'episodesList';

  const mounted = useRef<boolean>(false);

  /**
   * Force focus on the last watched episode based on the contentID targeted in the primary action click.
   * Should be trigger on each dpv5 refetch
   */
  const focusLastWatchedEpisode = useCallback(() => {
    if (primaryActionContentId) {
      const elementToBeFocused = document.getElementById(
        `${episodeIdPrefix}-item-${primaryActionContentId}`,
      );
      if (elementToBeFocused) {
        // Focus on the last watch episode
        store.getActiveLayer().focus(store.currentBinder, elementToBeFocused);
      } else {
        // Go back to the first episode
        store
          .getActiveLayer()
          .focus(store.currentBinder, store.currentBinder?.getElements()?.[0]);
      }
    }
  }, [episodeIdPrefix, primaryActionContentId, store]);

  useEffect(() => {
    if (!$_BUILD_RENDERMODE_CSR) {
      return;
    }

    if (mounted.current) {
      focusLastWatchedEpisode();
    } else {
      mounted.current = true;
    }
  }, [primaryActionContentId, focusLastWatchedEpisode]);
};
